.screenLayout {
  display: flex;
  flex-direction: column;
  //background-color: #ff0000;
  justify-content: flex-start;
  align-items: flex-start;
}

.pageHeader {
  display: flex;
  flex-direction: column;
  //background-color: #00ff00;
  justify-content: flex-start;
  align-items: flex-start;
}

.screenSize {
  max-width: 1200px;
}

.header-title {
  font-size: large;
}