body,
html {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

body {
  background-size: cover;
  background-image: url("./naf-id-bg.jpg");
}

h1 {
  font-size: 34px;
}

p {
  font-size: 18px;
  text-align: start;
  margin-bottom: 12px;
}

hr {
  background-color: #fde12d;
  border: 2px solid #fde12d;
  width: 80px;
  border-radius: 6px;
  height: 1px;
  margin: 20px auto 30px;
}

label {
  font-size: 16px;
  margin-bottom: 2px;
}

form {
  margin: 35px 0 45px;
}

.form-control {
  height: 50px;
  border-radius: 0;
  border-bottom: 2px solid black;
}

.page-container {
  height: 100vh;
}

.login-container {
  flex: 1;
}

#naf-logo {
  width: 43px;
  height: 43px;
  margin: 51px 14px 51px 51px;
}

.naf-logo-background {
  display: flex;
  align-items: center;
}

#back-to-naf {
  background-color: white;
  padding: 0 20px;
  align-items: center;
  cursor: pointer;
  border-radius: 18px;
  display: none;
}

#back-to-naf p {
  margin: 1px 0 0 10px;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

#back-to-naf p::after {
  content: "Tilbake til naf.no";
}

.arrow-icon {
  transform: scaleX(-1);
  width: 18px;
  height: 18epx;
}

.login-box {
  padding: 70px 30px 45px;
  background-color: #fff;
  border-radius: 5px;
  /* Prevent ugly shrinking */
  min-width: 300px;
  max-width: 375px;
}

.login-header {
  text-align: center;
}

.login-header img {
  width: 75px;
}

.btn-primary,
.btn-primary:focus {
  color: black;
  background-color: #ffdc00;
  border-color: #ffdc00;
  min-height: 55px;
  font-size: 18px;
  margin-bottom: 25px;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:active {
  color: black;
  border-color: #fff300;
  background-color: #fff300;
}

.btn-link {
  color: #156eae;
  text-decoration: underline;
}

.btn-link:hover {
  text-decoration: none;
}

#error-message {
  display: none;
  word-break: break-word;
}

#info-message {
  display: none;
}

#show-hide-password {
  display: none;
  position: relative;
  cursor: pointer;
  float: right;
  margin-top: -50px;
  object-fit: none;
  width: 70px;
  padding-left: 20px;
  height: 50px;
  z-index: 10;
}

/* On Microsft Edge and IE there is already a built-in show password function */
@supports (-ms-ime-align: auto) {
  #show-hide-password {
    visibility: hidden;
  }
}

@media all and (-ms-high-contrast: none) {
  #show-hide-password {
    visibility: hidden;
  }
}

/* Desktop specific */
@media screen and (min-width: 481px) {
  .page-container::after {
    content: "";
    height: 200px;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  body,
  html {
    background-image: none;
    height: 100vh;
  }

  h1 {
    font-size: 28px;
  }

  hr {
    margin: 16px auto;
  }

  p,
  button {
    font-size: 16px;
  }

  label {
    font-size: 14px;
  }

  form {
    margin: 20px 0;
  }

  #naf-logo {
    margin: 30px 8px 30px 30px;
  }

  .naf-logo-background {
    background-image: url("./naf-id-bg.jpg");
    background-size: cover;
    background-position: center;
    max-height: 125px;
    flex: 1;
  }

  #back-to-naf p {
    margin: 1px 0 0 6px;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
  }

  #back-to-naf p::after {
    content: "Naf.no";
  }

  .login-box {
    max-width: 100%;
    height: 100%;
  }

  .btn-primary {
    font-size: 16px;
    margin: 12px 0;
  }

  .btn-link {
    font-size: 16px;
  }

  .form-control {
    font-size: 14px;
  }
}
