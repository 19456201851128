.LinkButton {
  display: inline;
  background: none;
  border: none;
  font-size: 1em;
  padding: 0;
  color: #007bff;
}

.LinkButton:hover {
  text-decoration: underline;
}
