.selected-data-preview-form {
  height: 50px;
  border: 1px solid rgb(225, 228, 228);
  background-color: #f1f2f2;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.centered-text {
  flex: 1;
  display: flex;
  margin-block-end: 0;
  margin-block-start: 0;
}

.btn-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary:disabled, .btn-primary:disabled:hover,
.btn-primary.disabled, .btn-primary.disabled:hover {
  background-color: #ccc;
  border-color: #ccc;
  color: black;
  cursor: default;
}

input[name="otc"]:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
}

h2 {
  font-size: 28px;
}

::-ms-clear{display:none;}

@media screen and (max-width: 480px) {
  .selected-data-preview-form {
    font-size: 14px;
  }

  h2 {
    font-size: 22px;
  }
}
